import React from 'react';
import ReactDOM from 'react-dom';
import "../../App.css"
import "./project.css"
import seoul1 from "../../images/projects/seoul1.jpg"
import seoul2 from "../../images/projects/seoul2.jpg"
import { Carousel } from "react-bootstrap"
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";


// Images
import Landscript1 from "../../images/projects/Landscripts/Landscripts Pt 1_01.jpg"
import Landscript2 from "../../images/projects/Landscripts/Landscripts Pt 1_02.jpg"
import Landscript3 from "../../images/projects/Landscripts/Landscripts Pt 1_03.jpg"
import Landscript4 from "../../images/projects/Landscripts/Landscripts Pt 1_04.jpg"
import Landscript5 from "../../images/projects/Landscripts/Landscripts Pt 1_05.jpg"
import Landscript6 from "../../images/projects/Landscripts/Landscripts Pt 1_06.jpg"
import Landscript7 from "../../images/projects/Landscripts/Landscripts Pt 1_07.jpg"
import Landscript8 from "../../images/projects/Landscripts/Landscripts Pt 1_08.jpg"
import Landscript9 from "../../images/projects/Landscripts/Landscripts Pt 1_09.jpg"
import Landscript10 from "../../images/projects/Landscripts/Landscripts Pt 1_10.jpg"
import Landscript11 from "../../images/projects/Landscripts/Landscripts Pt 1_11.jpg"
import Landscript12 from "../../images/projects/Landscripts/Landscripts Pt 1_12.jpg"
import Landscript13 from "../../images/projects/Landscripts/Landscripts Pt 1_13.jpg"
import Landscript14 from "../../images/projects/Landscripts/Landscripts Pt 1_14.jpg"
import Landscript15 from "../../images/projects/Landscripts/Landscripts Pt 1_15.jpg"

import Lightbox from 'react-image-lightbox';

export default function Project1 (){


const Images = [ 
  Landscript1,
  Landscript2,
  Landscript3,
  Landscript4,
  Landscript5,
  Landscript6,
  Landscript7,
];

const options = {
    settings: {
      overlayColor: "white",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
      disablePanzoom: true,
    },
    buttons: {
        showDownloadButton: false,
        showAutoplayButton: false,
        showFullscreenButton: false,
        showThumbnailsButton: false,
        iconColor: "black",
        backgroundColor: "white"
    },
    caption: {
      captionColor: "black",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
      showCaption:false,

    }
  };
  
  

    return(
    <div className="App">  
            <div className="main-project">  
                    <div className="text-box">
                      
                    <h5 className="project-h">LANDSCRIPTS FOR NEW COMMUNITIES:
                        SEOUL VIA VIENNA Pt. 1
                    </h5>
                    <h5 className ="project-sh">To The Top</h5>
<div className="project-info">
Academic advisor: Seung-H Sang, Mladen Jadric<br></br>
In collaboration with Lidia Paluch and Maria Ilinca Suciu<br></br>
TU Vienna<br></br>
Bachelor thesis project, Spring 2017
</div>

<hr/>
<div className="project-fliestext">
  Hope Village is a shanty town located in Nowon district in Seoul. With the background
  of new initiatives for public housing development, the project “Landscripts
  for New Communities: Seoul via Vienna” consists of in total eleven designs planned
  for the small town in Hope Village. Based on the experience of Seoul and Vienna,
  the project is based on the principle that affordable housing is a right, a necessary
  part of a just, inclusive, and sustainable city.<br/>
  The main concepts of the projects are presented in eight categories: topography,
  pad, road, way of living, design, environment, micro-economy, and community.
  Based on selected typologies and design principles, concrete methods for accessing
  the high-density low-floor area are introduced through the designs. The project
  illustrates the various layers and contexts of modern urban conditions and the potential
  for high-density, low-rise residential models for future Seoul.<br/><br/>
  The growing privatization of residential buildings, the increasing consolidation of
  South Korean cities, and problems arising from the war-induced migrations of
  displaced persons and migrant residents, are just some developmental parallels
  between the European and Korean cities. The new generation of urban residents
  apparently wants the two cities to live differently, forcing architects, city planners
  and city administrations to deal with alternative models of housing and urban life
  in a political and creative way.<br/>
  In this project, we want to find, explore and implement new ideas and models
  for the city of Seoul, which should be innovative, interesting and acceptable like
  alternative model for the current situation.<br/><br/>
  The project consists of two buildings which enhance the landscape. The rooftops
  were inspired by the well-known mountains nearby, “Bulamsan”. They also represent
  existing houses, while the stairs that are cut into the building remind of the
  narrow village roads.<br/>
  The composition of the floor plans is based on the analysis on the Korean culture
  and Korean traditional house, “Hanok”. The Korean culture is inspired by the
  landscapes and nature on the pure beauty but also on the rational functionality and
  practicality.<br/>
  Between the two building blocks, an open space represented by green terraces are
  created. Under those terraces are various common rooms where dwellers can interact
  with each other and serve at the same time as a connection between the two
  blocks. To achieve a cost-efficient and practical system, the interior of the apartments
  is equipped with movable walls with functional capacities, which enhance
  the Korean traditional way of living - a flexible way of living in a neutral space
  </div>



                    </div>
                    <div>
                    <SRLWrapper options={options} >


      
                    <Carousel className="project-carousel" 
    nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" /> } 
    prevIcon={<span aria-hidden="true" className="carousel-control-pfev-icon" /> } 
    indicator={<span aria-hidden="true" className="carousel-indicators"/>}
    interval={2000}>

      <Carousel.Item >
        <img className="project-teaser-picture"
          src={Landscript1}
          alt="First slide"
        />

  </Carousel.Item>

  <Carousel.Item>

  <img className="project-teaser-picture"
      src={Landscript2}
      alt="Third slide"/>

   </Carousel.Item>

  <Carousel.Item>

  <img className="project-teaser-picture"
      src={Landscript3}
      alt="gismo"/>

   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript4}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript5}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript6}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript7}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript8}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript9}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript10}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript11}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript12}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript13}
      alt="Third slide"/>
   </Carousel.Item>

   <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript14}
      alt="Third slide"/>
   </Carousel.Item>
   <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript15}
      alt="Third slide"/>
   </Carousel.Item>

</Carousel>
   </SRLWrapper>

</div>
                    </div>
                <div>
            </div>
      
    </div>  

    );
}