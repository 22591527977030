import React, {Fragment, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { NavLink, Link} from "react-router-dom"
import "../App.css" 

import "./Navbar.css"
import MediaQuery from 'react-responsive';

 

export default function Navbar (){

    const [name, setName] = useState("Y")
    const [loading, setLoading] = useState(1)
    const [active, setActive] = useState("1")
    

    useEffect(() => {         
        if(loading === 1)  {
            setLoading(2)
            setTimeout(() => {   setName("YE"); }, 100);
            setTimeout(() => {   setName("YEO"); }, 200);
            setTimeout(() => {   setName("YEON"); }, 300);
            setTimeout(() => {   setName("YEON"); }, 400);
            setTimeout(() => {   setName("YEON "); }, 450);
            setTimeout(() => {   setName("YEON-K"); }, 500);
            setTimeout(() => {   setName("YEON-KY"); }, 600);
            setTimeout(() => {   setName("YEON-KYU"); }, 700);
            setTimeout(() => {   setName("YEON-KYU "); }, 800);
            setTimeout(() => {   setName("YEON-KYU L"); }, 900);
            setTimeout(() => {   setName("YEON-KYU LE"); }, 1000);
            setTimeout(() => {   setName("YEON-KYU LEE"); }, 1100);
        }
           
    } , []);

     
         
   
    

    return(
    <nav className="Navbar">
                <Fragment>

            <NavLink exact path to="/" className="Navbar-logo"  data-track='hover' id="Typewriter">{name} </NavLink> 
        </Fragment>

        <div   className="Navbar-link" >  
        
        <NavLink exact path to="/projects" className="Navbar-link-color" activeClassName="Navbar-link-active" > Projects </NavLink>
        <NavLink exact path to="/about"  className="Navbar-link-color" activeClassName="Navbar-link-active" > About </NavLink>
        <NavLink exact path to="/contact" className="Navbar-link-color" activeClassName="Navbar-link-active" > Contact </NavLink>

    </div>   


    </nav>

    );
}