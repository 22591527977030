import React from 'react';
import ReactDOM from 'react-dom';
import "../App.css" 
import "./projects/project.css"
import seoul1 from "../images/projects/seoul1.jpg"
import seoul2 from "../images/projects/seoul2.jpg"


export default function Contact (){


    return(
        <div className="App">  
                <div className="main-project">  
                        <div className="text-box-project">
                        Contact:<br/>
                        <a href = "mailto: yeon-kyu.lee@aaschool.ac.uk">yeon-kyu.lee@aaschool.ac.uk</a>

                        </div>
                        
                        </div>
                    <div>
                </div>
          
        </div>  
    
        );
    }