import React from 'react';
import ReactDOM from 'react-dom';
import "../App.css" 
import "./projects/project.css"
import seoul1 from "../images/projects/seoul1.jpg"
import seoul2 from "../images/projects/seoul2.jpg"


export default function About (){


    return(
        <div className="App">  
                <div className="main-project">  
                        <div className="text-box-about">
                        Yeon-Kyu Lee is a Korean-Austrian architect, based in Vienna and London.<br/>

She studied at RWTH Aachen, Technical University of Vienna and Architectural Association in London. <br/>
<br/>


Her interest sits on the divide between architecture and urbanism with a focus on housing and domesticity. Understanding the needs and potential of the places in which a project is embedded, is central to her working process. Yeon-Kyu employs many methods to test and communicate her ideas including drawing, modelling, photography, collage and writing. 

<br/>
<br/><br/>

<hr></hr>
<br/>
<br/>

Exhibition: <br/>

2018	<br/>
Landscripts For New Communities: Seoul Via Vienna Exhibition, Seoul Center for Architecture and Urbanism, Seoul/South Korea<br/>
<br/>

2017 	<br/>
Seoul Biennale of Architecture and Urbanism, Dongdaemun Design Plaza, Seoul/South Korea
<br/><br></br>
<br/>

Publication: <br/>

2018	<br/>
SPACE Magazine, “Alternatives to Urban Low-Rise Residential Areas – Landscripts for New Communities: Seoul via Vienna”, Issue 605, April 2018<br/>
<br/>

2018	<br/>
w(i)de AR Magazine, “지문은 이식되지 않는다”, Issue 61, May/June 2018<br/>

<br/>
<br/>
<hr></hr>
<br/><br/>

Contact:<br/>
e-mail:		yeon-kyu.lee@aaschool.ac.uk


                        </div>
                        <div>
                            </div>
                        </div>
                    <div>
                </div>
          
        </div>  
    
        );
    }