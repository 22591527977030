import React from 'react';
import ReactDOM from 'react-dom';
import "../../App.css"
import "./project.css"
import seoul1 from "../../images/projects/seoul1.jpg"
import seoul2 from "../../images/projects/seoul2.jpg"
import { Carousel } from "react-bootstrap"
import { SRLWrapper } from "simple-react-lightbox";

// Images
import HelloWood1 from "../../images/projects/HelloWood/Hello Wood_01.jpg"
import HelloWood2 from "../../images/projects/HelloWood/Hello Wood_02.jpg"
import HelloWood3 from "../../images/projects/HelloWood/Hello Wood_03.jpg"
import HelloWood4 from "../../images/projects/HelloWood/Hello Wood_04.jpg"
import HelloWood5 from "../../images/projects/HelloWood/Hello Wood_05.jpg"
import HelloWood6 from "../../images/projects/HelloWood/Hello Wood_06.jpg"
import HelloWood7 from "../../images/projects/HelloWood/Hello Wood_07.jpg"
import HelloWood8 from "../../images/projects/HelloWood/Hello Wood_08.jpg"

import Lightbox from 'react-image-lightbox';

export default function Project1 (){


const Images = [ 
HelloWood1,
  HelloWood2,
  HelloWood3,
  HelloWood4,
  HelloWood5,
  HelloWood6,
  HelloWood7,
  HelloWood8,

];

const options = {
  settings: {
    overlayColor: "white",
    autoplaySpeed: 1500,
    transitionSpeed: 900,
    disablePanzoom: true,
  },
  buttons: {
      showDownloadButton: false,
      showAutoplayButton: false,
      showFullscreenButton: false,
      showThumbnailsButton: false,
      iconColor: "black",
      backgroundColor: "white"
  },
  caption: {
    captionColor: "black",
    captionFontWeight: "300",
    captionTextTransform: "uppercase",
    showCaption:false,

  }
};

    return(
    <div className="App">  
            <div className="main-project">  
                    <div className="text-box">
                      
                    <h5 className="project-h">HELLO WOOD: PROJECT VILLAGE


                    </h5>
                    <h5 className ="project-sh">Constructing a village on the border of the ideal and real: Project Hello Pizza</h5>
<div className="project-info">

Team leaders: Gian Maria Socci, Zsófia Szonja Illés, Danny Wills<br/>
In collaboration with: Guiseppe Ferrigno, Cristiano Gerardi, Mathias Gommier,
Amy Grounsell, Emma Henderson, Jimmy Lei, Wendy Leung, Caroline Moinel,
Cezar Moldovan, Hanna Rudner and Hannah Sheerin<br/>
Hello Wood Summer School, July 2017

</div>

<hr/>
<div className="project-fliestext">


Throughout history the oven was a cornerstone of village life - the place where families gathered, news was shared, politics debated, and community was built. Hello Pizza is a project that wants to re-discover this important typology while transforming it with new shared functions missing from the Hello Wood village. The project aims to create community through the process of cooking, specifically centered on making pizza.
People not only eat and prepare food around a big communal stove, but also talk, gossip, and live their social lives. The goal of the project is to draw all the settlers into making pizza, so that the communal kitchen is used not only to serve up the inhabitants’ food. The counters around the stove are at the same time both workspaces and dining tables. Symbolically deconstructing the framework around the kitchen and opening up the workings of the space, the counters can be supported from either inside or outside, which means there is enough room to enable one person to eat their pizza while another makes the dough a couple of tables down.

The oven at the center of the courtyard is surrounded by a modular table where all of the processes of making pizza become shared moments - growing herbs and ingredients, preparation of the dough, baking, eating, and cleaning, including compost and other sustainable circular systems. Through the layered assembly of both construction elements and acquired ingredients, the pizza kitchen will grow in complexity and variety - expressing the multiple backgrounds and tastes of those coming together to eat, drink, and celebrate.
The stove, given that it’s not made of wood, is a permanent structure and suggests that the kitchen with the garden behind it will be serving the village’s inhabitants for years to come.
<hr></hr>
<div className ="project-copyright">Photos courtesy of: Tamas Bujnovszky, Guiseppe Ferrigno and Danny Wills </div>


  </div>



                    </div>
                    <div>

                    <SRLWrapper options={options} >

                    <Carousel className="project-carousel" 
    nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" /> } 
    prevIcon={<span aria-hidden="true" className="carousel-control-pfev-icon" /> } 
    indicator={<span aria-hidden="true" className="carousel-indicators"/>}
    interval={2000}>
      <Carousel.Item >
        <img className="project-teaser-picture"
          src={HelloWood1}
          alt="First slide"
        />

  </Carousel.Item>
  
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={HelloWood2}
      alt="Third slide"/>
   </Carousel.Item>
 
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={HelloWood3}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={HelloWood4}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={HelloWood5}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={HelloWood6}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={HelloWood7}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={HelloWood8}
      alt="Third slide"/>
   </Carousel.Item>


</Carousel>
   </SRLWrapper>

</div>
                    </div>
                <div>
            </div>
      
    </div>  

    );
}