import React from 'react';
import ReactDOM from 'react-dom';
import {NavLink } from 'react-router-dom';
import "../App.css" 
import main0 from "../images/main0.jpg"
import main1 from "../images/main1.jpg"
import main2 from "../images/main2.jpg"

import LandscriptCover from "../images/projects/Landscripts/Landscripts Pt 1_00_Projektcover.jpg"
import LandscriptCover2 from "../images/projects/Landscripts2/Landscripts Pt 2_00_Projektcover.jpg"
import HelloWoodCover from "../images/projects/HelloWood/Hello Wood_00_Projektcover.jpg"
import LaJalousieCover from "../images/projects/LaJalousie/La Jalousie_00_Projektcover.jpg"


export default function Projects (){

const projects = [
        {
        Text: "LANDSCRIPTS FOR NEW COMMUNITIES: SEOUL VIA VIENNA Pt. 1"
      },
        {
          Text: "LANDSCRIPTS FOR NEW COMMUNITIES: SEOUL VIA VIENNA Pt. 2"
        },
        {
          Text: "HELLO WOOD: PROJECT VILLAGE"
        },
        {
          Text: "LA JALOUSIE"
        },
      ]

    return(
    <div className="App">  
      <div className="Main">  

      <div className="project-frame"> 

        <NavLink exact path to="/to-the-top" className="link">
          <img src={LandscriptCover} alt="Logo" className="Project-picture"/>
          <div className="texts" unselectable="on">{projects[0].Text}</div>

        </NavLink>
      </div>



<div className="project-frame"> 
<NavLink exact path to="/continuity" className="link">

<img src={LandscriptCover2} alt="Logo" className="Project-picture"/>
    <div className="texts" unselectable="on">{projects[1].Text}</div>
    </NavLink>

</div>


<div className="project-frame"> 
<NavLink exact path to="/hello-wood" className="link">

<img src={HelloWoodCover} alt="Logo" className="Project-picture"/>
    <div className="texts" unselectable="on">{projects[2].Text}</div>
    </NavLink>

</div>
        <div className="project-frame"> 
      <NavLink exact path to="/la-jalousie" className="link">

      <img src={LaJalousieCover} alt="Logo" className="Project-picture"/>


            <div className="texts" unselectable="on">{projects[3].Text}</div>
            </NavLink>

            </div>

        
    </div>  

</div>  

    );
}
