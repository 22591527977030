import logo from './logo.svg';
import './App.css';
import Dashboard from "./components/Dashboard"
import Navbar from "./components/Navbar"
import Contact from "./components/Contact"
import Projects from "./components/Projects"
import Seoulviavienna from "./components/projects/Project1"
import React from "react";
import { BrowserRouter, Route , Switch} from "react-router-dom"; 
import Project1 from './components/projects/Project1';
import Project2 from './components/projects/Project2';
import Project3 from './components/projects/Project3';
import Project4 from './components/projects/Project4';
import About from './components/About';
import SimpleReactLightbox from "simple-react-lightbox";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
       <Navbar/>

      <Switch >
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about" component={About} />
        <SimpleReactLightbox>

        <Route exact path="/to-the-top" component={Project1} />
        <Route exact path="/continuity" component={Project2} />
        <Route exact path="/hello-wood" component={Project3} />
        <Route exact path="/la-jalousie" component={Project4} />
        </SimpleReactLightbox>


      </Switch>
      </BrowserRouter>


    </div>

  );
}

export default App;
