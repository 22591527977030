import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import "../App.css" 
import main0 from "../images/main0.jpg"
import main1 from "../images/main1.jpg"
import main2 from "../images/main2.jpg"
import { Link, NavLink, withRouter } from "react-router-dom"
import { Carousel } from "react-bootstrap"
import LandscriptLandingpage1 from "../images/projects/Landscripts/Landscripts Pt 1_00_Startseite_01.jpg"
import LandscriptLandingpage2 from "../images/projects/Landscripts/Landscripts Pt 1_00_Startseite_02.jpg"
import LandscriptLandingpage3 from "../images/projects/Landscripts2/Landscripts Pt 2_00_Startseite.jpg"
import HelloWoodLandingPage from "../images/projects/HelloWood/Hello Wood_00_Startseite.jpg"
import LaJalousieLandingPage from "../images/projects/LaJalousie/La Jalousie_00_Startseite.jpg"
  

   
export default function  Dashboard  ( ){
 
  const images = [
    {
    Image: LandscriptLandingpage1,
    Link: '/to-the-top'
  },
    {
      Image: LandscriptLandingpage3,
      Link: '/continuity'

    },
    {
      Image: LandscriptLandingpage2,
      Link: '/to-the-top'

    },
    {
      Image: HelloWoodLandingPage,
      Link: '/hello-wood'
    },
    {
      Image: LaJalousieLandingPage,
      Link: '/la-jalousie'
    }
  ]
  const [randomNumber, setRandomNumber] = useState((Math.floor(Math.random()*(images.length))))
  const [a, setA] = useState(1)
  const [b, setB] = useState(2)
  const [c, setC] = useState(3)
  const [d, setD] = useState(4)
  const [status, setStatus] =useState("")

  useEffect(() => {    
    setStatus("sdf")

      if (randomNumber + 5 === images.length   ) {
      setA(1)
      setB(2) 
      setC(3) 
      setD(4) 
      setStatus("5 Fall")

      }

      if (randomNumber + 4 === images.length   ) {
        setA(2)
        setB(3) 
        setC(4) 
        setD(0) 
      setStatus("4 Fall")

      }
      if (randomNumber + 3 === images.length   ) {
        setA(3)
        setB(4) 
        setC(0) 
        setD(1) 
        setStatus("3 Fall")
 
      }
      if (randomNumber + 2 === images.length) {
        setA(4)
        setB(0) 
        setC(1) 
        setD(2) 
        setStatus("2 Fall")

      }
      if (randomNumber + 1 === images.length) {
        setA(0)
        setB(1) 
        setC(2) 
        setD(3) 
        setStatus("1 Fall")

      }

      
 },[])
      
     

  
  return(
    <div className="App">  

    <div className="Main">  
    <div>
    
        
    <Carousel className="dashboard-carousel"
    nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" /> } 
    prevIcon={<span aria-hidden="true" className="carousel-control-next-icon" /> } 
    indicators={false}
    interval={2000}>
      <Carousel.Item>
      <NavLink exact path to={images[randomNumber].Link} className="link">

        <img className="carousel"
          src={images[randomNumber].Image}
          alt="First slide"
      
        />
        </NavLink>
  </Carousel.Item>
  <Carousel.Item>
  <NavLink exact path to={images[a].Link} className="link">
  <img className="carousel"

      src={images[a].Image}
      alt="Third slide"
    />

</NavLink>

  </Carousel.Item>

  <Carousel.Item>
  <NavLink exact path to={images[b].Link} className="link">

  <img className="carousel"

      src={images[b].Image}
      alt="Third slide"
    />

</NavLink>

  </Carousel.Item>
  <Carousel.Item>
  <NavLink exact path to={images[c].Link} className="link">

  <img className="carousel"

      src={images[c].Image}
      alt="Third slide"
    />

</NavLink>

  </Carousel.Item>


</Carousel>
  
  </div>

  </div>




    </div>

    );
    }
 