import React from 'react';
import ReactDOM from 'react-dom';
import "../../App.css"
import "./project.css"
import seoul1 from "../../images/projects/seoul1.jpg"
import seoul2 from "../../images/projects/seoul2.jpg"
import { Carousel } from "react-bootstrap"
import { SRLWrapper } from "simple-react-lightbox";

// Images
import LaJalousie1 from "../../images/projects/LaJalousie/La Jalousie_01.jpg"
import LaJalousie2 from "../../images/projects/LaJalousie/La Jalousie_02.jpg"
import LaJalousie3 from "../../images/projects/LaJalousie/La Jalousie_03.jpg"
import LaJalousie4 from "../../images/projects/LaJalousie/La Jalousie_04.jpg"
import LaJalousie5 from "../../images/projects/LaJalousie/La Jalousie_05.jpg"
import LaJalousie6 from "../../images/projects/LaJalousie/La Jalousie_06.jpg"
import LaJalousie7 from "../../images/projects/LaJalousie/La Jalousie_07.jpg"
import LaJalousie8 from "../../images/projects/LaJalousie/La Jalousie_08.jpg"
import LaJalousie9 from "../../images/projects/LaJalousie/La Jalousie_09.jpg"
import LaJalousie10 from "../../images/projects/LaJalousie/La Jalousie_10.jpg"
import LaJalousie11 from "../../images/projects/LaJalousie/La Jalousie_11.jpg"
import LaJalousie12 from "../../images/projects/LaJalousie/La Jalousie_12.jpg"
import LaJalousie13 from "../../images/projects/LaJalousie/La Jalousie_13.jpg"


import Lightbox from 'react-image-lightbox';

export default function Project1 (){


const Images = [ 
  LaJalousie1,
  LaJalousie2,
  LaJalousie3,
  LaJalousie4,
  LaJalousie5,
  LaJalousie6,
  LaJalousie7,
  LaJalousie8,
  LaJalousie9,
  LaJalousie10,
  LaJalousie11,
  LaJalousie12,
  LaJalousie13,

];
const options = {
    settings: {
      overlayColor: "white",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
      disablePanzoom: true,
    },
    buttons: {
        showDownloadButton: false,
        showAutoplayButton: false,
        showFullscreenButton: false,
        showThumbnailsButton: false,
        iconColor: "black",
        backgroundColor: "white"
    },
    caption: {
      captionColor: "black",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
      showCaption:false,
    }
  };
  

    return(
    <div className="App">  
            <div className="main-project">  
                    <div className="text-box">
                      
                    <h5 className="project-h">LA JALOUSIE


                    </h5>
                    <h5 className ="project-sh">Reinterpreting the communal living</h5>
<div className="project-info">

Academic advisor: Michael Schluder<br></br>
In collaboration with Aknur Zhussip<br></br>
Technical University of Vienna<br></br>
Fall 2018<br></br>

</div>

<hr/>
<div className="project-fliestext">


The project “La Jalousie” is an ode to the novel of the same name by the French writer Alain Robbe-Grillet. The narration of the life in a banana plantage in Central America is reinterpreted and translated to a hemp plantage in Kahlenberg, a sunny location near Vienna, Austria.
The aspects of communal living and working at the same place should be accentuated and strengthened. The focus is on concurrent development of architecture, statics and building technology in one project. A cost-conscious and resource-efficient design comes to the fore as well.
<br></br><br></br>

“The shadow of the column, though it is already very long, would have to be nearly a yard longer to reach the little round spot on the flagstones. From the latter runs a thin vertical thread which increases in size as it rises from the concrete substructure. It then climbs up the wooden surface, from lath to lath, growing gradually larger until it reaches the windowsill. But its progression is not constant: the imbricated arrangement of the boards intercepts its route by a series of equidistant projections where the liquid spreads out more widely before continuing its ascent. On the sill itself, the paint has largely flaked off after the streak occurred, eliminating about three-quarters of the red trace.”
The concept of this project is building up on the analysis, interpretation and reconstruction of the novel “La Jalousie”. It was essential to extract the atmosphere and details described in the book.
<br></br><br></br>

The programmatic and spatial concept is based on the lifecycle of hemp. To allow an uninterrupted process of producing hemp fabric, the space has to be flexible throughout the year. To achieve an open floor plan, moving walls on a raster of 4.20m x 4.20m are placed to generate different dimensions of space, which can be easily adapted to the needs of the residents, workers and visitors or depending on the various programmatic events.
<br></br>
<br></br>

Timber-frame construction was chosen as the main construction of the building. The visible columns and beams give a structure and an aesthetic rhythm to the inside space. The movable wall-system is connected to the beams with its rail. The wooden frame construction on the first and second storey is surrounded and protected by an outside wall out of concrete, which is at the same time responsible for a better temperature ratio. A jalousie as a sunscreen-system in front of the large-scale glass curtain wall provides additionally a better indoor climate quality. The timber-frame construction is used for the upper apartments as well, which is covered by lamella facade and stays invisible.



  </div>



                    </div>
                    <div>
                    <SRLWrapper options={options} >

      
                    <Carousel className="project-carousel" 
    nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" /> } 
    prevIcon={<span aria-hidden="true" className="carousel-control-pfev-icon" /> } 
    indicator={<span aria-hidden="true" className="carousel-indicators"/>}
    interval={2000}>
      <Carousel.Item >
        <img className="project-teaser-picture"
          src={LaJalousie1}
          alt="First slide"
        />

  </Carousel.Item>
  
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie2}
      alt="Third slide"/>
   </Carousel.Item>
 
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie3}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie4}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie5}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie6}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie7}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie8}
      alt="Third slide"/>
   </Carousel.Item>
   <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie9}
      alt="Third slide"/>
   </Carousel.Item>
   <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie10}
      alt="Third slide"/>
   </Carousel.Item>
   <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie11}
      alt="Third slide"/>
   </Carousel.Item>
   <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie12}
      alt="Third slide"/>
   </Carousel.Item>
   <Carousel.Item>
  <img className="project-teaser-picture"
      src={LaJalousie13}
      alt="Third slide"/>
   </Carousel.Item>


</Carousel>
   </SRLWrapper>

</div>
                    </div>
                <div>
            </div>
      
    </div>  

    );
}