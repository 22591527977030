import React from 'react';
import ReactDOM from 'react-dom';
import "../../App.css"
import "./project.css"
import seoul1 from "../../images/projects/seoul1.jpg"
import seoul2 from "../../images/projects/seoul2.jpg"
import { Carousel } from "react-bootstrap"
import { SRLWrapper } from "simple-react-lightbox";


// Images
import Landscript1 from "../../images/projects/Landscripts2/Landscripts Pt 2_01_A.jpg"
import Landscript2 from "../../images/projects/Landscripts2/Landscripts Pt 2_02.jpg"
import Landscript3 from "../../images/projects/Landscripts2/Landscripts Pt 2_03.jpg"
import Landscript4 from "../../images/projects/Landscripts2/Landscripts Pt 2_04.jpg"
import Landscript5 from "../../images/projects/Landscripts2/Landscripts Pt 2_05.jpg"
import Landscript6 from "../../images/projects/Landscripts2/Landscripts Pt 2_06.jpg"
import Landscript7 from "../../images/projects/Landscripts2/Landscripts Pt 2_07.jpg"
import Landscript8 from "../../images/projects/Landscripts2/Landscripts Pt 2_08.jpg"
import Landscript9 from "../../images/projects/Landscripts2/Landscripts Pt 2_09.jpg"
import Landscript10 from "../../images/projects/Landscripts2/Landscripts Pt 2_010.jpg"
import Landscript11 from "../../images/projects/Landscripts2/Landscripts Pt 2_11.jpg"
import Landscript12 from "../../images/projects/Landscripts2/Landscripts Pt 2_12.jpg"
import Landscript13 from "../../images/projects/Landscripts2/Landscripts Pt 2_13.jpg"
import Landscript14 from "../../images/projects/Landscripts2/Landscripts Pt 2_14.jpg"
import Landscript15 from "../../images/projects/Landscripts2/Landscripts Pt 2_15.jpg"

import Lightbox from 'react-image-lightbox';

export default function Project1 (){


const Images = [ 
  Landscript1,
  Landscript2,
  Landscript3,
  Landscript4,
  Landscript5,
  Landscript6,
  Landscript7,
];

const options = {
    settings: {
      overlayColor: "white",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
      disablePanzoom: true,
    },
    buttons: {
        showDownloadButton: false,
        showAutoplayButton: false,
        showFullscreenButton: false,
        showThumbnailsButton: false,
        iconColor: "black",
        backgroundColor: "white"
    },
    caption: {
      captionColor: "black",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
      showCaption:false,

    }
  };
  
    return(
    <div className="App">  
            <div className="main-project">  
                    <div className="text-box">
                      
                    <h5 className="project-h">LANDSCRIPTS FOR NEW COMMUNITIES:
SEOUL VIA VIENNA Pt. 2

                    </h5>
                    <h5 className ="project-sh">Continuity</h5>
<div className="project-info">
Academic advisor: Seung-H Sang, Mladen Jadric<br/>
Individual project<br/>
TU Vienna<br/>
Fall 2017<br/>

</div>

<hr/>
<div className="project-fliestext">
The Hope Village is one of the last remaining villages from the Sixties in Seoul. It
is a place where many former inhabitants and incidents left their footprints, whether
a daily occurrence or a significant event. It’s been a place where people in the
neighborhood came together and shared their everyday life.<br/>

This project tries to hold the essence of the Hope Village by keeping its existing
structure, as the existing pads, roads, topography and materiality in a new interpreted
way. The locals should be encouraged to explore the space in-between, use
the shared space for interacting as community and find their privacy in the flexible
designed living spaces, which can be transformed to their needs and wishes. The
place should transmit the spirit of the Hope Village and continue to keep its old
memories.<br/>

The focus in the second part of “Landscripts for New Communities: Seoul via Vienna”
was on the four newly added categories sustainability, economic efficiency,
locality and workability. The main aim was to develop the conceptual project from
the first part to a more realistic and concrete design.

<br/>
<br/>

The village has an area of 30.600 m2 and is located on a steep slope averaging
north/north west direction. It rises 35 meters from the lowest point at 65 m.a.s.l.
to the highest point at 100 m.a.s.l. The topography is terraced by pads that cover
24.700 m2 which is about 80% of the entire site. There are approximately 270
units in the existing one storey buildings and they have a total footprint of 11.300
m2, which makes BCR as well as FAR to about 38%. Subtracing the roads, BCR
and FAR is 48%.<br/>

In the proposed masterplan, the footprint of the new buildings is about 17.000 m2,
which makes BCR 56%. With an average of 3-4 storey buildings, the built area is
approximately 51.600 m2, generating FAR 168%. When subtracting the roads, BCR
is 71% and FAR 212%. The proposal contains about 900 units, with an average of
55 m2 per unit.
<br/><br/>

Common spaces are represented by narrow streets. The space being so narrow between
the buildings, the locals are being constantly pushed to interact with others.
With time, a strong community has been formed. The roads in different levels and
with different widths offer the people to gather together and to use the space for
different functions over the whole year. To remain the existing spirit of the Hope
Village, keeping the existing pad was necessary. The new structures remind of the
old senses of the houses from the sixties in a newly interpretated way.<br/>

By using the local materials, the new houses can blend in easily into the surrounding.
Another target was to achieve a natural lighting and ventilation while keeping
the existing structures, pads and streets.

  </div>



                    </div>
                    <div>
                    <SRLWrapper options={options} >

      
                    <Carousel className="project-carousel" 
    nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" /> } 
    prevIcon={<span aria-hidden="true" className="carousel-control-pfev-icon" /> } 
    indicator={<span aria-hidden="true" className="carousel-indicators"/>}
    interval={2000}>
      <Carousel.Item >
        <img className="project-teaser-picture"
          src={Landscript1}
          alt="First slide"
        />

  </Carousel.Item>
  
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript2}
      alt="Third slide"/>
   </Carousel.Item>
 
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript3}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript4}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript5}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript6}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript7}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript8}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript9}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript10}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript11}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript12}
      alt="Third slide"/>
   </Carousel.Item>
  <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript13}
      alt="Third slide"/>
   </Carousel.Item>

   <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript14}
      alt="Third slide"/>
   </Carousel.Item>
   <Carousel.Item>
  <img className="project-teaser-picture"
      src={Landscript15}
      alt="Third slide"/>
   </Carousel.Item>

</Carousel>
                    </SRLWrapper >

</div>
                    </div>
                <div>
            </div>
      
    </div>  

    );
}